<template>
  <div class="home">
    <banner
        banner1="https://n.tobidad.com/site/videos/home/banner1.mp4?v=1.0.3"
        banner2="https://n.tobidad.com/site/videos/home/banner2.mp4?v=1.0.1"
        text="一款助力开发者精耕流量价值的聚合广告工具"
    />

    <div class="section feature">
      <h2>多维度助力开发者提升广告收益</h2>
      <h4>支持多家广告网络应用内竞价，通过竞价漏斗模型可建立应用内竞价的<br>全链路数据追踪，结合可视化看板高效提升变现收益。</h4>
      <div class="video-container">
        <video class="video" src="https://n.tobidad.com/site/videos/home/feature.mp4?v=1.0.1" poster="" preload="auto" autoplay muted playsinline=""></video>
        <div class="nav-btn">
          <svg width="1em" height="1em" t="1664162723198" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1771" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path fill="currentColor" d="M593.450667 512.128L360.064 278.613333l45.290667-45.226666 278.613333 278.762666L405.333333 790.613333l-45.226666-45.269333z" p-id="1772"></path>
          </svg>
        </div>
      </div>
      <div class="tabs">
        <div :class="tabClass(1)" title="A/B Test" @click="tabId = 1">
          <div class="text">A/B Test</div>
          <div class="desc">通过AB分流对比验证变现策略效果，针对性对变现策略进行迭代从而提升广告收益</div>
          <span class="bar"></span>
        </div>
        <div :class="tabClass(2)" title="多维数据能力" @click="tabId = 2">
          <div class="text">多维数据能力</div>
          <div class="desc">多种类报表细节呈现，以真实、准确的数据为开发者提供明晰的决策支持</div>
          <span class="bar"></span>
        </div>
        <div :class="tabClass(3)" title="定制化SDK下载" @click="tabId = 3">
          <div class="text">定制化SDK下载</div>
          <div class="desc">开发者可灵活根据业务诉求，集成应用需要的广告网络SDK</div>
          <span class="bar"></span>
        </div>
        <div :class="tabClass(4)" title="流量分组" @click="tabId = 4">
          <div class="text">流量分组</div>
          <div class="desc">按照不同用户标签、特征对流量进行群组划分，投其所好真正实现精细化运营</div>
          <span class="bar"></span>
        </div>
        <div :class="tabClass(5)" title="应用内竞价" @click="tabId = 5">
          <div class="text">应用内竞价</div>
          <div class="desc">率先推出应用内竞价漏斗模型，为开发者提供应用内竞价全链路数据</div>
          <span class="bar"></span>
        </div>
      </div>
    </div>

    <div class="section section1 active">
      <video class="video-bg" src="https://n.tobidad.com/site/videos/home/multi_chart.mp4?v=1.0.1" poster="" preload="auto" loop="" autoplay=""  muted playsinline=""></video>
      <div class="block-bg"></div>
      <div class="content">
        <div class="wrapper">
          <h2>多维度数据报表洞察变现机会与风险</h2>
          <div class="title-line"></div>
          <h4>根据不同场景业务需求，一键定制化生成多维度分析报表<br>可视化呈现和一站式分析助力数据洞察更精准</h4>
          <span class="link-btn">
              <span class="text">了解更多</span>
              <span class="arrow"></span>
            </span>
        </div>
      </div>
    </div>

    <div class="section section2">
      <video class="video-bg" src="https://n.tobidad.com/site/videos/home/data_insight.mp4?v=1.0.1" poster="" preload="auto" loop="" autoplay="" muted playsinline=""></video>
      <div class="block-bg"></div>
      <div class="content">
        <div class="wrapper">
          <h2>强大数据引擎驱动变现运营与决策</h2>
          <div class="title-line"></div>
          <h4>依托新型数据处理技术，提升数据分析“内力修为”，<br>为开发者提供更加透明、实时、精确的数据分析</h4>
          <span class="link-btn">
              <span class="text">了解更多</span>
              <span class="arrow"></span>
            </span>
        </div>
      </div>
    </div>

    <div class="section section3">
      <video class="video-bg" src="https://n.tobidad.com/site/videos/home/game.mp4" poster="" preload="auto" loop="" autoplay="" muted playsinline=""></video>
      <div class="block-bg"></div>
      <div class="content">
        <div class="wrapper">
          <h2>因地制宜搭建变现策略与定制化运营方案</h2>
          <div class="title-line"></div>
          <h4>凭借丰富的变现实践经验，针对性扫除开发者变现障碍，<br>定制化运营策略驱动ARPDAU提升高达248%，<br>成为业内知名游戏IP与非游厂商的一致选择</h4>
          <span class="link-btn">
              <span class="text">了解更多</span><span class="arrow"></span>
            </span>
        </div>
      </div>
    </div>

    <div class="section multiform" style="--0ecde720: #ff7700; --0ecde73f: #79c700; --0ecde75e: #00b3b0; --0ecde77d: #6649a3; --0ecde79c: #007cc9; --0ecde7bb: #fbbd08; --0ecde7da: #e03997;">
      <h2>支持丰富的广告样式，满足不同场景需求</h2>
      <div class="wrapper">
        <div class="image-forms">
          <h4>图文样式</h4>
          <div :class="cardClass(item.id)" v-for="item in imageCards" @click="cardId =item.id">
            <img :src="item.image" alt="" class="icon">
            <div class="text">
              <div class="title">{{ item.title }}</div>
              <div class="desc"> {{ item.desc }}</div>
            </div>
          </div>
        </div>
        <div class="demo-container">
          <span class="arrow" style="color: rgb(255, 255, 255);"></span>
          <img src="https://n.tobidad.com/site/images/multiform/native.png?v=1.0.2" alt="" :style="{display: cardId === 1 ? 'block' : 'none'}">
          <img src="https://n.tobidad.com/site/images/multiform/ocr.png?v=1.0.2" alt="" :style="{display: cardId === 2 ? 'block' : 'none'}">
          <img src="https://n.tobidad.com/site/images/multiform/banner.png?v=1.0.2" alt="" :style="{display: cardId === 3 ? 'block' : 'none'}">
          <img src="https://n.tobidad.com/site/images/multiform/insert.png?v=1.0.2" alt="" :style="{display: cardId === 4 ? 'block' : 'none'}">
          <video src="https://n.tobidad.com/site/videos/multiform/native.mp4?v=1.0.2" preload="auto" loop autoplay muted playsinline="" :style="{display: cardId === 5 ? 'block' : 'none'}"></video>
          <video src="https://n.tobidad.com/site/videos/multiform/moti.mp4?v=1.0.2" preload="auto" loop autoplay muted playsinline="" :style="{display: cardId === 6 ? 'block' : 'none'}"></video>
          <video src="https://n.tobidad.com/site/videos/multiform/full.mp4?v=1.0.2" preload="auto" loop autoplay muted playsinline="" :style="{display: cardId === 7 ? 'block' : 'none'}"></video>
        </div>
        <div class="video-forms">
          <h4>视频样式</h4>
          <div :class="cardClass(item.id)" v-for="item in videoCards" @click="cardId =item.id">
            <img :src="item.image" alt="" class="icon">
            <div class="text">
              <div class="title">{{ item.title }}</div>
              <div class="desc"> {{ item.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="platform-marquee">
      <h2>现已支持国内外主流广告平台</h2>
      <div class="marquee-container">
        <div class="vue3-marquee" style="--duration: 30s; --delay: 0s; --direction: normal; --pauseOnHover: running; --pauseOnClick: running; --loops: infinite; --gradient-color: rgba(255, 255, 255, 1), rgba(255, 255, 255, 0); --gradient-width: 15%; --min-width: 100%;">
          <div class="transparent-overlay"></div>
          <div class="overlay"></div>
          <div class="marquee">
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/sigmob.png?v=1.0.3" class="sigmob" alt=""><img src="https://n.tobidad.com/site/images/platform/hammer.svg" class="bidding-icon"></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/baidu.png?v=1.0.3" class="baidu" alt=""><img src="https://n.tobidad.com/site/images/platform/hammer.svg" class="bidding-icon"></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/google.png?v=1.0.3" class="google" alt=""><!----></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/vungle.png?v=1.0.3" class="vungle" alt=""><!----></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/applovin.png?v=1.0.3" class="applovin" alt=""><!----></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/tapadn.png?v=1.0.3" class="tapadn" alt=""><img src="https://n.tobidad.com/site/images/platform/hammer.svg" class="bidding-icon"></div>
          </div>
          <div class="marquee">
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/sigmob.png?v=1.0.3" class="sigmob" alt=""><img src="https://n.tobidad.com/site/images/platform/hammer.svg" class="bidding-icon"></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/baidu.png?v=1.0.3" class="baidu" alt=""><img src="https://n.tobidad.com/site/images/platform/hammer.svg" class="bidding-icon"></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/google.png?v=1.0.3" class="google" alt=""><!----></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/vungle.png?v=1.0.3" class="vungle" alt=""><!----></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/applovin.png?v=1.0.3" class="applovin" alt=""><!----></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/tapadn.png?v=1.0.3" class="tapadn" alt=""><img src="https://n.tobidad.com/site/images/platform/hammer.svg" class="bidding-icon"></div>
          </div>
        </div>
        <div class="vue3-marquee" style="--duration: 30s; --delay: 0s; --direction: reverse; --pauseOnHover: running; --pauseOnClick: running; --loops: infinite; --gradient-color: rgba(255, 255, 255, 1), rgba(255, 255, 255, 0); --gradient-width: 15%; --min-width: 100%;">
          <div class="transparent-overlay"></div>
          <div class="overlay"></div>
          <div class="marquee">
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/tencent.png?v=1.0.3" class="tencent" alt=""><img src="https://n.tobidad.com/site/images/platform/hammer.svg" class="bidding-icon"></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/ironsource.png?v=1.0.3" class="ironsource" alt=""><!----></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/kuaishou.png?v=1.0.3" class="kuaishou" alt=""><img src="https://n.tobidad.com/site/images/platform/hammer.svg" class="bidding-icon"></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/yky.png?v=1.0.3" class="yky" alt=""><!----></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/mtg.png?v=1.0.3" class="mtg" alt=""><img src="https://n.tobidad.com/site/images/platform/hammer.svg" class="bidding-icon"></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/unity.png?v=1.0.3" class="unity" alt=""><!----></div>
          </div>
          <div class="marquee">
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/tencent.png?v=1.0.3" class="tencent" alt=""><img src="https://n.tobidad.com/site/images/platform/hammer.svg" class="bidding-icon"></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/ironsource.png?v=1.0.3" class="ironsource" alt=""><!----></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/kuaishou.png?v=1.0.3" class="kuaishou" alt=""><img src="https://n.tobidad.com/site/images/platform/hammer.svg" class="bidding-icon"></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/yky.png?v=1.0.3" class="yky" alt=""><!----></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/mtg.png?v=1.0.3" class="mtg" alt=""><img src="https://n.tobidad.com/site/images/platform/hammer.svg" class="bidding-icon"></div>
            <div class="box"><img src="https://n.tobidad.com/site/images/platform/unity.png?v=1.0.3" class="unity" alt=""><!----></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import Banner from "@/components/Card/Banner";
export default {
  name: 'Home',
  components: {Banner},
  data() {
    return {
      tabId: 1,
      cardId: 1,
      imageCards: [
        {
          id: 1,
          image: 'https://n.tobidad.com/site/images/multiform/native_n.png',
          title: '信息流图片',
          desc: '契合应用原生环境的广告样式，提供浑然一体的用户体验图片广告',
        },
        {
          id: 2,
          image: 'https://n.tobidad.com/site/images/multiform/ocr_n.png',
          title: '开屏广告',
          desc: '应用开屏黄金展示位，越来越多品牌广告主的优先选择，收益更高',
        },
        {
          id: 3,
          image: 'https://n.tobidad.com/site/images/multiform/banner_n.png',
          title: '横幅广告',
          desc: '可灵活选择版位的标准横幅广告，样式美观灵活，适用于高曝光率场景',
        },
        {
          id: 4,
          image: 'https://n.tobidad.com/site/images/multiform/insert_n.png',
          title: '插屏图片',
          desc: '在应用开启、暂停、退出等节点，以半屏或全屏的形式弹出展示的图片广告',
        }
      ],
      videoCards: [
        {
          id: 5,
          image: 'https://n.tobidad.com/site/images/multiform/native_v.png',
          title: '信息流视频',
          desc: '契合应用原生环境的广告样式，提供浑然一体的用户体验视频广告',
        },
        {
          id: 6,
          image: 'https://n.tobidad.com/site/images/multiform/moti_v.png',
          title: '激励视频',
          desc: '在应用开启、暂停、退出等节点，以全屏的形式弹出展示的视频广告',
        },
        {
          id: 7,
          image: 'https://n.tobidad.com/site/images/multiform/full_v.png',
          title: '全屏视频',
          desc: '在应用开启、暂停、退出等节点，以全屏的形式弹出展示的视频广告',
        }
      ]
    }
  },
  created() {
    let that = this;
    setInterval(function () {
      that.tabId ++;
      if (that.tabId > 5) {
        that.tabId = 1;
      }
    }, 5000);

    setInterval(function () {
      that.cardId ++;
      if (that.cardId > 8) {
        that.cardId = 1;
      }

    }, 10000);
  },
  methods: {
    tabClass(id) {
      if (id === this.tabId) {
        return 'tab active';
      }
      return 'tab'
    },
    cardClass(id) {
      if (id === this.cardId) {
        return 'card active';
      }
      return 'card'
    }
  }
}
</script>
<style scoped>
.vue3-marquee {
  overflow-x: hidden!important;
  display: flex!important;
  flex-direction: row!important;
  position: relative;
  width: 100%
}

.vue3-marquee:hover div {
  animation-play-state: var(--pauseOnHover)
}

.vue3-marquee:active div {
  animation-play-state: var(--pauseOnClick)
}

.vue3-marquee>.marquee {
  flex: 0 0 auto;
  min-width: var(--min-width);
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: scroll var(--duration) linear var(--delay) var(--loops);
  animation-direction: var(--direction)
}

@keyframes scroll {
  0% {
    transform: translate(0)
  }

  to {
    transform: translate(-100%)
  }
}

.vue3-marquee>.overlay {
  position: absolute;
  width: 100%;
  height: 100%
}

.vue3-marquee>.transparent-overlay {
  position: absolute;
  width: 100%;
  height: 100%
}

.vue3-marquee>.overlay:before,.vue3-marquee>.overlay:after {
  background: linear-gradient(to right,var(--gradient-color));
  content: "";
  height: 100%;
  position: absolute;
  width: var(--gradient-width);
  z-index: 2
}

.vue3-marquee>.overlay:after {
  right: 0;
  top: 0;
  transform: rotate(180deg)
}

.vue3-marquee>.overlay:before {
  left: 0;
  top: 0
}

blockquote,body,dd,dl,dt,fieldset,figure,h1,h2,h3,h4,h5,h6,hr,html,iframe,legend,li,ol,p,pre,textarea,ul {
  margin: 0;
  padding: 0
}

*,:after,:before {
  box-sizing: border-box
}

svg {
  outline: none
}

a {
  color: inherit;
  text-decoration: none
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
}

:root {
  --header-height: 60px;
  --content-width: 1100px;
  --text-color: #333;
  --soft-text-color: #666;
  --weak-text-color: #999;
  --primary-color: #1881FF;
  --primary-hover-color: #3A93FF;
  --error-color: #F02E1B;
  --success-color: #52C41A;
  --warning-color: #fa8c16;
  --border-light-color: rgba(229, 231, 235);
  --border-color: #e0e0e6
}

html,body {
  font-family: Segoe UI,Inter var,PingFang SC,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Microsoft Yahei,Roboto,San Francisco,STHeiti,Hiragino Sans GB,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji"!important;
  font-size: 14px;
  width: 100%;
  color: var(--text-color);
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased
}

a {
  outline: none
}

input,textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid rgba(0,0,0,.15);
  line-height: 1.5715;
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  outline: none;
  border-radius: 2px;
  transition: all .2s;
  font-family: inherit;
  color: var(--text-color)
}

input:hover,textarea:hover,input:focus,textarea:focus {
  border-color: #18b7ff
}

input::-webkit-input-placeholder,textarea::-webkit-input-placeholder {
  color: #c1c1c1
}

video {
  -webkit-mask-image: -webkit-radial-gradient(white,black);
  mask-image: -webkit-radial-gradient(white,black);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh
}

.v-enter-active,.v-leave-active {
  transition: opacity 1s ease
}

.v-enter-from,.v-leave-to {
  opacity: 0
}

.video-bg,.img-bg {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 500px;
  max-height: 1200px;
  -o-object-fit: cover;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: opacity .3s
}

.banner,.section {
  position: relative;
  display: flex;
  height: 700px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden
}

.banner>.content,.section>.content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: var(--content-width)
}

.banner>.content .center-align-block,.section>.content .center-align-block {
  display: inline-block;
  text-align: center
}

.banner>.content .center-align-block h1,.section>.content .center-align-block h1 {
  letter-spacing: 4px;
  margin-right: -4px
}

.banner .main-btn,.section .main-btn {
  display: inline-block;
  background-color: #15adff;
  box-shadow: 0 4px 8px rgba(50,102,255,.2);
  width: -moz-max-content;
  width: max-content;
  padding: 10px 24px;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  cursor: pointer;
  margin-top: 15px
}

.banner .video-bg {
  background-color: #ebeef6
}

.primary-btn {
  background-image: linear-gradient(to bottom,#23D9FF 0,#15ADFF 100%);
  background-size: 100% 175%;
  background-position: center 100%;
  transition: .3s;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  outline: none
}

.primary-btn:hover,.primary-btn:focus,.primary-btn:active {
  background-position: center 40%
}

.title-line {
  width: 50px;
  height: 3px;
  background-color: currentColor;
  -webkit-clip-path: polygon(0% 0%,92% 0%,100% 100%,0% 100%);
  clip-path: polygon(0% 0%,92% 0%,100% 100%,0% 100%)
}

@media screen and (min-width: 1600px) {
  :root {
    --content-width: 1280px
  }

  .banner,.section {
    height: 750px;
    max-height: 1200px
  }
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border: 0 solid transparent;
  border-radius: 10px
}

</style>

<style scoped>
.feature {
  height: auto;
  text-align: center;
  padding: 70px 20px 60px;
  --stripe-color: rgba(99, 197, 247, .075);
  /*background-image: ;*/
  /*background-position-x: ;*/
  /*background-position-y: ;*/
  /*background-repeat: ;*/
  /*background-attachment: ;*/
  /*background-origin: ;*/
  /*background-clip: ;*/
  /*background-color: ;*/
  background-size: 100% 100%;
  background-blend-mode: multiply;
}

.feature h2 {
  font-size: 30px;
  margin-bottom: 15px;
}

.feature h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
}

.feature .video-container {
  position: relative;
}

.feature .video-container .nav-btn {
  position: absolute;
  top: 45%;
  right: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  color: rgba(36, 36, 36, 0.3);
  font-size: 26px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 5px;
}

.feature .video-container .nav-btn svg {
  transform: translate(1px);
}

.feature .video-container .nav-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
}

.feature .video {
  width: 960px;
  height: 500px;
  object-fit: cover;
  margin: 20px auto;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 14px 1px;
  background-image: url("https://n.tobidad.com/site/images/home/feature.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.feature .tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  line-height: 1.5;
  font-size: 15px;
}

.feature .tabs .tab {
  position: relative;
  width: 184px;
  margin: 0px 5px;
  padding: 10px 6px 15px;
  background-color: rgba(255, 255, 255, 0);
  transition: all 0.4s ease 0s;
}

.feature .tabs .tab .bar {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 0px;
  height: 3px;
  transform-origin: 0px 0px;
  background: linear-gradient(to right, rgb(36, 220, 255) 0%, rgb(21, 173, 255) 100%);
}

.feature .tabs .tab .text {
  font-weight: 500;
  font-size: 16px;
  padding: 5px;
  cursor: pointer;
}

.feature .tabs .tab .desc {
  color: var(--soft-text-color);
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  opacity: 0;
  max-height: 0px;
  transform: translateY(10px);
  transition: all 0.4s ease 0s;
  z-index: -1;
}

.feature .tabs .tab:nth-child(1) .bar {
  background: linear-gradient(to right, rgb(36, 220, 255) 0%, rgb(21, 173, 255) 100%);
}

.feature .tabs .tab:nth-child(2) .bar {
  background: linear-gradient(to right, rgb(160, 230, 0) 0%, rgb(150, 212, 47) 100%);
}

.feature .tabs .tab:nth-child(3) .bar {
  background: linear-gradient(to right, rgb(255, 195, 9) 0%, rgb(255, 148, 6) 100%);
}

.feature .tabs .tab:nth-child(4) .bar {
  background: linear-gradient(to right, rgb(214, 89, 195) 0%, rgb(163, 81, 177) 100%);
}

.feature .tabs .tab:nth-child(5) .bar {
  background: linear-gradient(to right, rgb(79, 243, 200) 0%, rgb(58, 180, 179) 100%);
}

.feature .tabs .tab.active {
  background-color: rgba(255, 255, 255, 0.5);
}

.feature .tabs .tab.active .desc {
  opacity: 1;
  max-height: 60px;
  transform: translateY(0px);
  z-index: 1;
}

.feature .tabs .tab.active .bar {
  width: 100%;
  transition: width 5s linear 0s;
}

.multiform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 60px 20px 120px;
  color: rgb(255, 255, 255);
  background: rgb(55, 45, 66);
}

.multiform h4 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 16px;
  text-align: center;
}

.multiform .image-forms, .multiform .video-forms {
  display: flex;
  flex-direction: column;
}

.multiform .card {
  --base-bg: rgba(255, 255, 255, .04);
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 1 0%;
  width: 360px;
  padding: 20px 24px;
  background-image: linear-gradient(to right,var(--base-bg) 0,var(--base-bg) 100%);
  background-size: 200% 100%;
  background-position: 99% 0px;
  cursor: pointer;
  transition: all 0.5s ease 0s;
}

.multiform .card:not(.multiform .card:first-of-type) {
  margin-top: 1rem;
}

.multiform .card .icon {
  width: 50px;
  height: 60px;
  object-fit: contain;
  margin-right: 20px;
}

.multiform .card .text {
  flex: 1 1 0%;
}

.multiform .card .title {
  font-size: 18px;
  margin-bottom: 8px;
}

.multiform .card .desc {
  color: var(--weak-text-color);
  line-height: 1.5;
}

.multiform .card::before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 0px;
  height: 1px;
  background-color: rgb(255, 255, 255);
}

.multiform .card.active {
  background-position: 0px 0px;
  transition: all 0.5s ease 0s;
}

.multiform .card.active .title {
  font-weight: 600;
}

.multiform .card.active .desc {
  color: rgb(255, 255, 255);
}

.multiform .card.active::before {
  width: 100%;
  transition: all 10s linear 0s;
}

.multiform .image-forms .card:nth-of-type(1) {
  background-image: linear-gradient(to right,var(--0ecde720) 0,var(--base-bg) 50%,var(--base-bg) 100%);
}

.multiform .image-forms .card:nth-of-type(2) {
  background-image: linear-gradient(to right,var(--0ecde73f) 0,var(--base-bg) 50%,var(--base-bg) 100%);
}

.multiform .image-forms .card:nth-of-type(3) {
  background-image: linear-gradient(to right,var(--0ecde75e) 0,var(--base-bg) 50%,var(--base-bg) 100%);
}

.multiform .image-forms .card:nth-of-type(4) {
  background-image: linear-gradient(to right,var(--0ecde77d) 0,var(--base-bg) 50%,var(--base-bg) 100%);
}

.multiform .video-forms .card:nth-of-type(1) {
  background-image: linear-gradient(to right,var(--0ecde79c) 0,var(--base-bg) 50%,var(--base-bg) 100%);
}

.multiform .video-forms .card:nth-of-type(2) {
  background-image: linear-gradient(to right,var(--0ecde7bb) 0,var(--base-bg) 50%,var(--base-bg) 100%);
}

.multiform .video-forms .card:nth-of-type(3) {
  background-image: linear-gradient(to right,var(--0ecde7da) 0,var(--base-bg) 50%,var(--base-bg) 100%);
}

.multiform .demo-container {
  position: relative;
  width: 268px;
  height: 568px;
  margin: 0px 120px;
}

.multiform .demo-container::after {
  content: "";
  position: absolute;
  inset: -16px -16px -14px;
  background-image: url("https://n.tobidad.com/site/images/iphone_box.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.multiform .demo-container .arrow {
  transition: color 0.5s linear 0s;
}

.multiform .demo-container .arrow::before, .multiform .demo-container .arrow::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.multiform .demo-container .arrow::before {
  top: -60px;
  width: 0px;
  height: 0px;
  font-size: 0px;
  overflow: hidden;
  border-style: solid;
  border-color: currentcolor transparent transparent;
  border-width: 24px 24px 0px;
}

.multiform .demo-container .arrow::after {
  bottom: -60px;
  width: 0px;
  height: 0px;
  font-size: 0px;
  overflow: hidden;
  border-style: solid;
  border-color: transparent transparent currentcolor;
  border-width: 0px 24px 24px;
}

.multiform .demo-container video, .multiform .demo-container img {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 32px;
  z-index: 1;
}

.platform-marquee {
  padding: 80px 0px;
  background-color: rgb(250, 250, 250);
  text-align: center;
}

.platform-marquee h2 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 60px;
}

.platform-marquee .marquee-container {
  width: 100%;
}

.platform-marquee .box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 200px;
}

.platform-marquee .box:nth-child(2n) {
  background-color: rgb(255, 255, 255);
}

.platform-marquee .box img {
  width: 240px;
}

.platform-marquee .box img.tapadn {
  width: 200px;
}

.platform-marquee .box .bidding-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 40px;
}

.home {
  min-width: 1440px;
}

.banner::after {
  content: "";
  position: absolute;
  inset: 0px;
  opacity: 0;
  background: linear-gradient(to right, rgba(231, 235, 242, 0.9) 0%, rgba(221, 228, 238, 0) 80%);
  transition: all 1s ease 0s;
}

.banner > .content {
  z-index: 1;
  opacity: 0;
  transition: all 1s ease 0s;
  width: 75%;
}

.banner > .content h1 {
  font-size: 46px;
  margin-bottom: 5px;
}

.banner > .content h2 {
  font-size: 30px;
  font-weight: 300;
  line-height: 1.5;
}

.banner.show-content::after {
  opacity: 1;
}

.banner.show-content > .content {
  opacity: 1;
}

.section h2 {
  font-size: 30px;
  margin-bottom: 10px;
}

.section h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  margin: 20px 0px;
}

.section .block-bg {
  position: absolute;
  width: 50%;
  height: 100%;
  transition: all 1s ease 0s;
}

.section > .content {
  width: auto;
  transform: translateY(-50%);
}

.section .wrapper {
  display: inline-block;
  text-align: left;
  transition: all 1s ease 0s;
}

.section.active .block-bg, .section.active .wrapper {
  opacity: 1;
  transform: translate(0px);
}

.section .link-btn {
  position: relative;
  display: inline-flex;
  padding: 12px 20px;
  border: 1px solid;
  font-weight: 500;
  cursor: pointer;
  line-height: 1;
}

.section .link-btn .text {
  transition: transform 0.2s ease 0s;
}

.section .link-btn .arrow {
  position: absolute;
  top: 50%;
  transform: translate(4px, -50%);
  right: 10px;
  font-size: 0px;
  opacity: 0;
  transition: all 0.2s ease 0s;
}

.section .link-btn .arrow::before, .section .link-btn .arrow::after {
  content: "";
  width: 0px;
  height: 0px;
  font-size: 0px;
  overflow: hidden;
  border-style: solid;
  border-color: transparent transparent transparent currentcolor;
  border-width: 5px 0px 5px 7px;
}

.section .link-btn .arrow::after {
  margin-left: -1px;
}

.section .link-btn:hover .text {
  transform: translate(-8px);
}

.section .link-btn:hover .arrow {
  opacity: 1;
  transform: translateY(-50%);
}

.section1 {
  overflow: hidden;
}

.section1 .video-bg {
  background-image: url("https://n.tobidad.com/site/images/home/multi_chart.jpg");
}

.section1 .block-bg {
  left: 0px;
  top: 0px;
  width: 40%;
  background-image: url("https://n.tobidad.com/site/images/home/triangle_yellow.png");
  background-position: 100% 55%;
  background-size: 110%;
  transform-origin: 0px 0px;
  opacity: 0;
  transform: translate(-50%);
}

.section1 > .content {
  left: 36.5%;
  transform: translate(-80%, -50%);
}

.section1 .wrapper {
  opacity: 0;
  transform: translate(30%);
}

.section2 {
  color: rgb(255, 255, 255);
  text-align: right;
}

.section2 > .content {
  top: 42%;
}

.section2 .video-bg {
  background-image: url("https://n.tobidad.com/site/images/home/data_insight.jpg");
}

.section2 .block-bg {
  right: 0px;
  top: 0px;
  width: 48%;
  background-image: url("https://n.tobidad.com/site/images/home/triangle_teal.png");
  background-position: 0px 36%;
  background-size: 140%;
  opacity: 0;
  transform: translate(50%);
}

.section2 .block-bg {
  opacity: 1;
  transform: translate(0);
}

.section2 > .content {
  left: 55%;
  transform: translate(25%, -50%);
}

.section2 .wrapper {
  opacity: 1;
  transform: translate(0);
}

.section3 {
  color: rgb(255, 255, 255);
}

.section3 > .content {
  top: 49%;
}

.section3 .video-bg {
  background-image: url("https://n.tobidad.com/site/images/home/game.jpg");
}

.section3 .block-bg {
  left: 0px;
  top: 0px;
  width: 50%;
  background-image: url("https://n.tobidad.com/site/images/home/triangle_green.png");
  background-position: 100% 50%;
  background-size: 120%;
  opacity: 0;
  transform: translate(-50%);
}

.section3 .block-bg {
  opacity: 1;
  transform: translate(0);
}

.section3 > .content {
  left: 45%;
  transform: translate(-115%, -50%);
}

.section3 .wrapper {
  opacity: 1;
  transform: translate(0);
}

.multiform .wrapper {
  display: flex;
}

.multiform h2 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 90px;
}
</style>


