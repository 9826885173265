<template>
  <div class="banner show-content">
    <video class="video-bg" :src="banner1" preload="auto" autoplay muted  :style="{opacity: opacity}" @ended="ended"></video>
    <video class="video-bg" :src="banner2" preload="auto" autoplay muted loop style="position: absolute; z-index: -1;"></video>
    <div class="content show-content" :style="{opacity: contentOpacity}">
      <div class="center-align-block">
        <h1>{{ text }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  props: {
    banner1: {
      type: String,
      required: true
    },
    banner2: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      contentOpacity: 0,
      opacity: 1,
    }
  },
  methods: {
    ended() {
      this.opacity = 0;
      this.contentOpacity = 1;
    }
  }
}
</script>

<style scoped>
.video-bg {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 500px;
  max-height: 1200px;
  -o-object-fit: cover;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: opacity .3s
}

.banner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden
}

.banner>.content {
  transition: 1s;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: var(--content-width)
}

.banner>.content h1 {
  font-size: 40px
}

.banner>.content .center-align-block {
  display: inline-block;
  text-align: center
}

.banner>.content .center-align-block h1 {
  letter-spacing: 4px;
  margin-right: -4px
}

.banner .video-bg {
  background-color: #ebeef6
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border: 0 solid transparent;
  border-radius: 10px
}

.contact {
  min-width: 1440px
}

@media screen and (min-width: 1600px) {
  :root {
    --content-width: 1280px
  }

  .banner{
    height: 750px;
    max-height: 1200px
  }

  .banner>.content {
    width: 950px
  }
}
</style>
