<template>
  <div class="directory">
    <h4 class="title">{{ title }}</h4>
    <a class="list" v-for="item in items" :href="item.path"> {{ item.title }}</a>
  </div>
</template>

<script>
export default {
  name: "Directory",
  props: {
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.directory {
  justify-content: center;
  padding: 20px;
  text-align: left;
  font-size: 12px;
  color: #999;
}
.directory .title {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  margin: 5px 0;
}
.directory .list {
  display: block;
  cursor: pointer;
  padding: 3px 0;
  transition: all .2s;
  color: inherit;
  text-decoration: none;
  outline: none;
}
</style>
