<template>
  <div class="header">
      <Nav></Nav>
  </div>
</template>

<script>
import Nav from '@/components/Header/Nav.vue'
import Logo from "@/components/Header/Logo.vue";

export default {
  name: "Header",
  components: {
    Logo,
    Nav
  }
}
</script>

<style scoped>
.header {
}
</style>
