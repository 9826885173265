<template>
  <div class="footer">
    <el-row>
      <el-col :span="6"><directory title="产品优势" :items="directory.first"></directory></el-col>
      <el-col :span="6"><directory title="合作案例" :items="directory.second"></directory></el-col>
      <el-col :span="6"><directory title="资讯活动" :items="directory.third"></directory></el-col>
      <el-col :span="6"><directory title="关于我们" :items="directory.forth"></directory></el-col>
    </el-row>
    <copyright></copyright>
  </div>
</template>

<script>
import Directory from "@/components/Footer/Directory";
import Copyright from "@/components/Footer/Copyright";

export default {
  name: "FooterIndex",
  components: {
    Directory,
    Copyright
  },
  data() {
    return {
      directory: {
        first: [
          {
            title: "行业痛点",
            path: "https://www.baidu.com"
          }
        ],
        second: [
        ],
        third: [
        ],
        forth: [
        ]
      }
    }
  }
}
</script>
<style>
.footer {
  background-color: #363636
}
</style>
