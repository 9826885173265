<template>
  <div id="app">
    <Header></Header>
    <router-view/>
    <Footer></Footer>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from '@/components/Header/Index.vue'
import Footer from '@/components/Footer/Index.vue'

export default {
  components: {
    Header,
    Footer
  },
}
</script>
<style>
html, body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
