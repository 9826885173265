<template>
  <div class="logo">
    <img src="" alt=""/>
  </div>
</template>

<script>
export default {
  name: "Logo"
}
</script>

<style scoped>

</style>
