<template>
  <div class="nav">
    <el-menu
        :default-active="activeIndex"
        background-color="#ffffffcc"
        text-color="#000"
        class="el-menu-demo"
        mode="horizontal"
        router
    >
      <template v-for="item in menus">
        <el-submenu v-if="item.hasOwnProperty('children') && item.children.length > 0" :index="item.id" :key="item.id">
          <template slot="title">
            {{ item.name }}
          </template>
          <el-menu-item v-for="child in item.children" :key="child.id" :index="child.id" :route="item.path">
            {{ child.name }}
          </el-menu-item>
        </el-submenu>
        <el-menu-item v-else :index="item.path" :key="item.id">
          {{ item.name }}
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import Logo from "@/components/Header/Logo";
export default {
  name: "NavIndex",
  components: {
    Logo
  },
  data() {
    return {
      activeIndex: '1',
      menus: [
        {
          id: '1',
          name: '首页',
          path: '/',
        },
        {
          id: '2',
          name: '产品服务',
          path: '/product',
        },
        {
          id: '3',
          name: '合作案例',
          path: '/case',
        },
        {
          id: '4',
          name: '资讯活动',
          path: '/news',
        },
        {
          id: '5',
          name: '联系我们',
          path: '/contact',
        },
      ]
    };
  },
  mounted() {
  },
  methods: {
  }
}
</script>
