<template>
  <div class="copyright">
  </div>
</template>

<script>
export default {
  name: "Copyright"
}
</script>

<style scoped>
.copyright {
  font-size: 12px;
  text-align: center;
  border-top: 1px solid rgba(255,255,255,.05);
  color: #999;
}
</style>
